import { permission_By_Role } from "./../../store/api/devices";
const DocMenuConfig = [
  {
    heading: "Provisioning",
    route: "/provisioned",
    devices: [
      {
        heading: "Devices",
        route: "/provisioning/devices/repository",
        svgIcon: "/media/icons/duotune/general/gen019.svg",
        fontIcon: "bi-cart",
        permission: "r-repo",
      },
      {
        svgIcon: "/media/icons/duotune/general/gen027.svg",
        fontIcon: "bi-cart",
        heading: "Bins",
        route: "/bins",
        permission: "bins-list",
      },
      {
        svgIcon: "/media/icons/duotune/general/gen015.svg",
        fontIcon: "bi-cart",
        heading: "Tracker",
        route: "/trackers",
        permission: "trackers-list",
      },
      {
        svgIcon: "/media/icons/duotune/art/art002.svg",
        fontIcon: "bi-cart",
        heading: "Send Devices To Platform",
        route: "/provisioning/devices/repo_to_platform",
        permission: "send_devices_to_platform",
      },
    ],
  },
  {
    heading: "Administration",
    route: "/administration",
    devices: [
      {
        svgIcon: "/media/icons/duotune/finance/fin001.svg",
        fontIcon: "bi-printer",
        heading: "Companies",
        route: "/administration/companies",
        permission: "company-list",
      },
      {
        svgIcon: "/media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-printer",
        heading: "Users",
        route: "/administration/users",
        permission: "user-list",
      },
      {
        svgIcon: "/media/icons/duotune/general/gen007.svg",
        fontIcon: "bi-printer",
        heading: "Notification",
        route: "/administration/notifications",
        permission: "notifications-list",
      },
    ],
  },
  {
    heading: "Management",
    route: "/downlink",
    devices: [
      {
        svgIcon: "/media/icons/duotune/coding/cod007.svg",
        fontIcon: "bi-printer",
        heading: "Downlinks",
        route: "/downlink/vie",
        permission: "downlink-management",
      },
      {
        svgIcon: "/media/icons/duotune/coding/cod009.svg",
        fontIcon: "bi-printer",
        heading: "Webhooks",
        route: "/webhooks",
        permission: "webhooks-list",
      },
      {
        svgIcon: "/media/icons/duotune/technology/teh004.svg",
        fontIcon: "bi-printer",
        heading: "Permissions",
        route: "/management/permission",
        permission: "assign-permission",
      },
      {
        svgIcon: "/media/icons/duotune/ecommerce/ecm006.svg",
        fontIcon: "bi-printer",
        heading: "Routing",
        route: "/management/routing",
        permission: "routing-list",
      },
    ],
  },
  {
    heading: "Data Analysis",
    route: "/DataAnalysis",
    devices: [
      {
        svgIcon: "/media/icons/duotune/graphs/gra010.svg",
        fontIcon: "bi-printer",
        heading: "Companies Report",
        route: "/companyreport",
        permission: "companies-report",
      },
      {
        svgIcon: "/media/icons/duotune/electronics/elc008.svg",
        fontIcon: "bi-printer",
        heading: "Devices Report",
        route: "/devicereport",
        permission: "devices-report",
      },
    ],
  },
  {
    heading: "Help Section",
    route: "/help",
    devices: [
      {
        svgIcon: "/media/icons/duotune/files/fil009.svg",
        fontIcon: "bi-printer",
        heading: "Production Script",
        route: "/production",
        permission: "production-script",
      },
      {
        svgIcon: "/media/icons/duotune/files/fil002.svg",
        fontIcon: "bi-printer",
        heading: "Credential Sheets",
        route: "/credentialsheets",
        permission: "credential-sheets",
      },
      // {
      //   svgIcon: "/media/icons/duotune/finance/fin007.svg",
      //   fontIcon: "bi-printer",
      //   heading: "Sigfox Integration",
      //   route: "/help/viewer",
      // },
      // {
      //   svgIcon: "/media/icons/duotune/finance/fin007.svg",
      //   fontIcon: "bi-printer",
      //   heading: "Nbiot",
      //   route: "/help/viewer",
      // },
      // {
      //   svgIcon: "/media/icons/duotune/finance/fin007.svg",
      //   fontIcon: "bi-printer",
      //   heading: "LoraVan Integration",
      //   route: "/help/viewer",
      // },
    ],
  }
];

export default DocMenuConfig;
